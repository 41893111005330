import React, { useState } from "react"
import { GiHamburgerMenu } from "react-icons/gi"
import { graphql, useStaticQuery, Link } from "gatsby"
import { VscChromeClose } from "react-icons/vsc"
import Image from "../image"
import "./header.styles.css"

const Header = () => {
  const data = useStaticQuery(graphql`
    query Headerquery {
      markdownRemark(fileAbsolutePath: { regex: "/header.md/" }) {
        frontmatter {
          headertext
          headerlink
        }
      }
    }
  `)

  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  const closeMobileMenu = () => setClick(false)

  return (
    <div className="main-navbar fixed-top">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <div style={{ width: "50px", height: "55px" }}>
            <Image name="logo.png" />
          </div>
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? (
            <VscChromeClose className="black" />
          ) : (
            <GiHamburgerMenu className="black" />
          )}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-links">
            <Link to="/#home" className="textD" onClick={closeMobileMenu}>
              Beranda
            </Link>
          </li>
          <li className="nav-links">
            <Link to="/#tentang" className="textD" onClick={closeMobileMenu}>
              Tentang Kami
            </Link>
          </li>
          <li className="nav-links">
            <Link to="/#fitur" className="textD" onClick={closeMobileMenu}>
              Fitur
            </Link>
          </li>
          <li className="nav-links">
            <Link to="/#kerja" className="textD" onClick={closeMobileMenu}>
              Cara Kerja
            </Link>
          </li>
          <li className="nav-links">
            <Link to="/#tutorial" className="textD" onClick={closeMobileMenu}>
              Tutorial
            </Link>
          </li>
          <li className="btn-vw">
            <Link
              to={data.markdownRemark.frontmatter.headerlink}
              className="nav-links2"
              onClick={closeMobileMenu}
            >
              <button className="join-btn">
                {data.markdownRemark.frontmatter.headertext}
              </button>
            </Link>
          </li>
        </ul>
        <div className="social-header">
          <Link
            to={data.markdownRemark.frontmatter.headerlink}
            onClick={closeMobileMenu}
          >
            <button className="join-btn">
              {data.markdownRemark.frontmatter.headertext}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
