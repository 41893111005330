import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "./footer.styles.css"
import footerLogo from "../../../static/images/footer-logo.svg"
import Tiktok from "../../../static/images/tiktok.svg"
import WhatsApp from "../../../static/images/whatsapp.svg"
import Facebook from "../../../static/images/facebook.svg"
import Intagram from "../../../static/images/instagram.svg"
import Twiter from "../../../static/images/twiter.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Footerquery {
      markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
        frontmatter {
          about
          address
          email
          facebook
          instagram
          whatsapp
          tiktok
          twiter
          copyright
          alamat
        }
      }
    }
  `)

  const style = {
    height: "28px",
    width: "28px",
    marginLeft: "20px",
  }
  let alamat =  data.markdownRemark.frontmatter.alamat
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="footer-content">
            <div className="content-section content-section1">
              <div className="footer-logo">
                <img src={footerLogo} alt="footer-logo" loading="lazy" />
              </div>
              {alamat ? 
              <p className="email">{data.markdownRemark.frontmatter.address}</p>
              : null}
              <p>
                <a href={data.markdownRemark.frontmatter.email}>
                  {data.markdownRemark.frontmatter.email}
                </a>
              </p>
            </div>
            {/* INFORMATION  */}
            <div className="content-section content-section2">
              <h5>Informasi Nibrasplay</h5>
              <ul>
                <li>
                  <Link to="/about">Tentang Kami</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Kebijakan Privasi</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Syarat dan Ketentuan</Link>
                </li>
                <li>
                  <Link to="/contact">Kontak Kami</Link>
                </li>
                {/* <li><Link to="https://seller.nibrasplay.co.id">Seller / Dropshiper</Link></li> */}
              </ul>
            </div>
            {/* A WORD */}
            <div className="content-section content-section3">
              <h5>Sepatah Kata</h5>
              <p>{data.markdownRemark.frontmatter.about}</p>
            </div>
            {/* ICON SIZE MOBILE */}
            <div className="content-section content-section3 hidden1">
              <div className="footer-social">
                <Link
                  to={data.markdownRemark.frontmatter.twiter}
                  target="_blank"
                >
                  <img src={Twiter} loading="lazy" alt="insta" style={style} />
                </Link>
                <Link
                  to={data.markdownRemark.frontmatter.whatsapp}
                  target="_blank"
                >
                  <img
                    src={WhatsApp}
                    loading="lazy"
                    alt="insta"
                    style={style}
                  />
                </Link>
                <Link
                  to={data.markdownRemark.frontmatter.facebook}
                  target="_blank"
                >
                  <img
                    src={Facebook}
                    loading="lazy"
                    alt="insta"
                    style={style}
                  />
                </Link>
                <Link
                  to={data.markdownRemark.frontmatter.instagram}
                  target="_blank"
                >
                  <img
                    src={Intagram}
                    loading="lazy"
                    alt="insta"
                    style={style}
                  />
                </Link>
                <Link
                  to={data.markdownRemark.frontmatter.tiktok}
                  target="_blank"
                >
                  <img src={Tiktok} loading="lazy" alt="insta" style={style} />
                </Link>
              </div>
            </div>
          </div>
          {/* ICON NORMAL  */}
          <div className="footer-bottom">
            <p>{data.markdownRemark.frontmatter.copyright}</p>
            <div className="footer-social hidden2">
              <Link to={data.markdownRemark.frontmatter.twiter} target="_blank">
                <img src={Twiter} loading="lazy" alt="insta" style={style} />
              </Link>
              <Link
                to={data.markdownRemark.frontmatter.whatsapp}
                target="_blank"
              >
                <img src={WhatsApp} loading="lazy" alt="insta" style={style} />
              </Link>
              <Link
                to={data.markdownRemark.frontmatter.facebook}
                target="_blank"
              >
                <img src={Facebook} loading="lazy" alt="insta" style={style} />
              </Link>
              <Link
                to={data.markdownRemark.frontmatter.instagram}
                target="_blank"
              >
                <img src={Intagram} loading="lazy" alt="insta" style={style} />
              </Link>
              <Link to={data.markdownRemark.frontmatter.tiktok} target="_blank">
                <img src={Tiktok} loading="lazy" alt="insta" style={style} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
